import React, {Component} from "react"
import { Link, StaticQuery, graphql } from 'gatsby'

import style from '../css/components-css/blogfeed.module.sass'

//TODO HOW TO MAKE THIS BETTER
//use transform json for graphql
//reverse the array in a smarter way
//general refractoring

class BlogFeed extends Component{

    state = {
        articlesData: [],
    }

    componentWillMount(){
        this.getArticlesData(this.props.graphqldata.allDirectory.edges);
    }

    getArticlesData(graphqlEdges){
        var numOfArticles = graphqlEdges.length;
        var folderNames = []
        for(var i = 0; i < numOfArticles; i++){
            folderNames.push(graphqlEdges[i].node.name)
        }
        let articlesDataArray = []
        for(let j = 0; j < numOfArticles; j++){
            import('../pages/blog/'+folderNames[j]+'/article-data.json').then(
                module => {
                    var data = module.default
                    //getting the cover image
                    var imageName = data.image
                    var imageProcessed
                    import("../pages/blog/"+folderNames[j]+"/"+imageName).then(
                        module => {
                            imageProcessed = module.default
                            data.imageProcessed = imageProcessed
                            //got the image let's order it in ascending order and push the new element
                            //if the array is empty or the new date is greater than the one of the last element of the array lets just push the data
                            if(articlesDataArray.length == 0 || this.isGraterDateThan(data.date,articlesDataArray[articlesDataArray.length-1].date)){articlesDataArray.push(data)}
                            else{
                                for(let k = 0; k < articlesDataArray.length; k++){
                                   if(!this.isGraterDateThan(data.date,articlesDataArray[k].date)){
                                        articlesDataArray.splice( k, 0, data);
                                        break
                                   }
                                }
                            }
                            this.setState({articlesData: articlesDataArray})
                        }
                    ).catch(function(error) {
                        console.log("Error retriving image: " + error);
                    });
                }
            ).catch(function(e) {
                console.log("Error retriving data: " + e);
            });
        }
    }

    //returns true if dateA > dateB and false if dateA <= dateB. As input takes two strings in the format "dd-mm-yy"
    isGraterDateThan(dateAString,dateBString){
        var dateA = this.getSplitDate(dateAString)
        var dateB = this.getSplitDate(dateBString)
        //HORRIBLY WRITTEN FUCK IT IM SO TIRED I DONT EVEN KNOW WHO I AM
        if(dateA.year > dateB.year){return true}
        else if(dateA.year < dateB.year){return false}
        else{
            if(dateA.month > dateB.month){return true}
            else if(dateA.month < dateB.month){return false}
            else{
                if(dateA.day > dateB.day){return true}
                else{return false}
            }
        }
    }

    //gets a date like DD-MM-YY and formats it (Es. 04-12-00 = December 4, 2000)
    getFormattedDate(unformattedDate){
        const MONTHS = ["January","February","March","April","May","June","July","August","September","October","November","December"];
        var date = this.getSplitDate(unformattedDate);
        var formDay = date.day
        var formMonth = MONTHS[(Number(date.month)-1)]
        var formYear = String(2000 + Number(date.year))
        var formDate = formMonth + " " + formDay + ", " + formYear
        return formDate 
    }

    getSplitDate(unformattedDate){
        var splitDate = unformattedDate.split("-")
        var date = {day: splitDate[0], month: splitDate[1], year: splitDate[2]}
        return date
    }

    render(){
        return(
            //reversing the array because elements are rendered from the top down and i want the latst element of the array to render the top-most elment
            this.state.articlesData.slice(0).reverse().map((articleData,i) =>
                <article key={"article-"+i} className={style.article}>
                    <div className={style.articleDataSection}>
                        <Link className={style.articleTitle} to={"/blog/"+articleData.slug}><h3>{articleData.title}</h3></Link>
                        <p className={style.articleExcerpt}>{articleData.excerpt}</p>
                        <p className={style.articleDate}>{this.getFormattedDate(articleData.date)}</p>
                    </div>
                    <Link className={style.articleImage + " non-text"} style = {{backgroundImage: "url(" + articleData.imageProcessed + ")"}} to={"/blog/"+articleData.slug}><div></div></Link>
                </article>
            )
        )
    }
}

export default BlogFeed
