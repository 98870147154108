import React from "react"
import { Link } from 'gatsby'

import "../css/main.sass"
import style from "../css/pages-css/index.module.sass"

import Layout from "../components/Layout"
import BlogFeed from "../components/BlogFeed"

export default function({data}){

  return(
    <Layout>
    <main className={style.main + " standard-page"}>
      <section className={style.welcomeSection}>
        <h1 className={style.welcomeTitle + " fw-black big"}>Welcome!</h1>
        <h3 className={style.welcomeText}>Why don't you take a look around?<br/>Scroll down for the <Link to="/blog">Blog</Link>.<br/>Or maybe check the <Link to="/about">About Page</Link>.</h3>
      </section>
      <section className={style.blogFeedSection}>
        <BlogFeed graphqldata={data}></BlogFeed>
      </section>
    </main>
    </Layout>
  )
  
  // function getWelcomeText(){
  //   if(window.innerWidth > 550){
  //     return <h3 className={style.welcomeText}>Why don't you take a look around?<br/>Scroll down for the <Link to="/blog">Blog</Link>.<br/>Or maybe check the <Link to="/about">About Page</Link>.</h3>
  //   }
  // }

}



export const query = graphql`
query{
    allDirectory(filter: {dir: {eq: "C:/Users/user/Desktop/PersonalSite/PersonalSite/src/pages/blog"}}){
        edges{
          node{
            name
          }
        }
    }
}
`